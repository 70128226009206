<template>  
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.sw.overview')" class="socialwall overview">
    <CCol cols="12" xl="12" lg="12" md="12" sm="12">
      <CCard class="mb-0 h-100" v-bind:class="{'schedule' : activeView === 'schedule'}">
        <CCardHeader>
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{$t('sw.overview_social_wall2')}}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">                                      
              <loadingSpinner v-if="videosUploading.length > 0" mode="inline" :content="$t('upload.Uploading_videos')" class="uploading_videos"/>
              
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButtonGroup>
                  <CButton color="outline-secondary" v-for="(value, key) in ['schedule', 'list', 'cards']" :key="key" :pressed="value === activeView ? true : false" @click="activeView = value; currentPage = 1; getPostData();">
                    <i v-if="value === 'schedule'" class="fas fa-calendar-week"></i>
                    <i v-if="value === 'list'" class="fas fa-list"></i>
                    <i v-if="value === 'cards'" class="fas fa-th"></i>
                  </CButton>
                </CButtonGroup>
                <CButton v-if="checkPermission('connect.sw.neweditpost')" class="ml-2 mr-0 link" color="primary" @click="openSidebarRight('sw_post_details', { post_id_external:  null, schedule_for_datetime: null, back_to_preview: false })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{$t('sw.new_post')}}</span>
                </CButton>                
              </div>
            </CCol>  
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="activeView === 'schedule'" class="schedule h-100 d-flex flex-column">
            <div class="overview_tools">
              <CRow>
                <CCol cols="2" xl="2" class="pt-0 pb-0 text-left">
                  <b-datepicker v-model="startDate" 
                                icon="calendar-day" 
                                icon-pack="fas" 
                                :first-day-of-week="1" 
                                :show-week-number="true" 
                                :years-range="[-3, 10]" 
                                :placeholder="$t('common.click_to_select')" 
                                @input="getPostData()" 
                                class="d-inline-block align-middle">
                    <CButton color="primary" @click="startDate = new Date(); getPostData();"><i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}</CButton>
                  </b-datepicker>
                </CCol>

                <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                  <multiselect
                    class="data_table open_absolute"
                    v-model="filter.sw_groups" 
                    :options="swGroups" 
                    :multiple="true"
                    :close-on-select="false"
                    :placeholder="$t('sw.filter_groups2')" 
                    :selectLabel="$t('common.Add_group')" 
                    :selectedLabel="$t('Added')"
                    :deselectLabel="$t('Remove')"
                    track-by="sw_group_name" 
                    label="sw_group_name"
                    @input="getPostData()">
                    <span slot="noResult">{{$t('sw.no_groups_found2')}}</span>
                  </multiselect>
                </CCol>
                <!-- <CCol cols="4" xl="4" class="pt-0 pb-0 text-right align-middle">
                  <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="showInactive" size="is-small" @input="getPostData()">{{$t('common.Show_hidden')}}</b-switch>
                </CCol> -->
              </CRow>
            </div>
            <div class="week_days_container">
              <CRow class="h-100">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                  <div class="week_days h-100 d-flex">
                    <div v-for="(day, index) in weekDays" v-bind:key="index" class="day d-flex flex-column">                                            
                      <div class="date text-center">
                        <span class="font-weight-bold">{{day.date | moment("dd DD-MM")}}</span>
                        <i v-if="checkPermission('connect.sw.neweditpost') && day.available_for_scheduling === 1" class="fas fa-plus" v-bind:class="{'end' : index === 4}" @click="openSidebarForDate(day)"/>
                        <i v-if="index === 0" class="fas fa-chevron-circle-left" @click="startDate = new Date(previousDate); getPostData();"/>
                        <i v-if="index === 4" class="fas fa-chevron-circle-right" @click="startDate = new Date(nextDate); getPostData()"/>
                      </div>
                      <div class="post_container">
                        <div v-if="day.posts.length > 0">
                          <swPostCard v-for="(post, index) in day.posts"
                                      v-bind:key="post.post_id"
                                      :ref="'swPostCard' + index"
                                      :postData="post"
                                      :modules="modules"
                                      :platformPermissions="platformPermissions"
                                      :navigatable="true"
                                      :editable="true"
                                      :previewable="true"
                                      :preview="false"
                                      :showReplies="false"
                                      :showInsights="true"
                                      :scrollableContent="false"
                                      :openedInExp="true" 
                                      class="m-2"/>
                        </div>
                        <div v-else>
                          <div class="no_posts_scheduled text-center p-3">
                            <span class="material-icons w-auto d-block mb-2">calendar_today</span>
                            <span>{{$t('sw.no_posts_scheduled')}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </CCol>
              </CRow>
            </div>
          </div>

          <div v-if="activeView === 'list'" class="list_view">
            <div class="overview_tools">
              <CRow>
                <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                  <multiselect
                    class="data_table open_absolute"
                    v-model="filter.sw_groups" 
                    :options="swGroups" 
                    :multiple="true"
                    :close-on-select="false"
                    :placeholder="$t('sw.filter_groups2')" 
                    :selectLabel="$t('common.Add_group')" 
                    :selectedLabel="$t('Added')"
                    :deselectLabel="$t('Remove')"
                    track-by="sw_group_name" 
                    label="sw_group_name"
                    @input="getPostData()">
                    <span slot="noResult">{{$t('sw.no_groups_found2')}}</span>
                  </multiselect>
                </CCol>
                <!-- <CCol cols="6" xl="6" class="pt-0 pb-0 text-right">
                  <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getPostData()">{{$t('common.Show_hidden')}}</b-switch>
                </CCol> -->
              </CRow>
            </div>
            <div class="data_table_container">
              <CRow>
                <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                  <loadingSpinner mode="auto" v-if="loadingListPosts" :content="$t('common.Loading')"/>
                  <b-table v-else 
                           ref="postTable"
                           class="data_table includes_dropdown"
                           :data="posts"
                           :striped="true"
                           :hoverable="true"
                           :scrollable="true"
                           :mobile-cards="true"
                           :paginated="isPaginated"
                           :per-page="perPage"
                           :current-page.sync="currentPage"
                           :pagination-simple="isPaginationSimple"
                           :pagination-position="paginationPosition"
                           default-sort-direction='desc'
                           :sort-icon="sortIcon"
                           :sort-icon-size="sortIconSize"
                           default-sort="created"
                           :total="totalItems"
                           backend-pagination
                           @page-change="onListPageChange">
                    
                    <template slot-scope="props">
                      <b-table-column field="title" :label="$t('common.Post')" :searchable="searchEnabled">
                        <span v-line-clamp="1" v-html="props.row.title" class="pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external })"></span>
                        <span class="post_meta pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external });">{{props.row.created | moment("DD-MM-YYYY HH:mm")}} door {{props.row.posted_by_name}}</span>
                        <div class="item_tags d-flex flex-row">
                          <b-tag v-if="props.row.is_breaking_news" class="mt-1 warning">BREAKING</b-tag>
                          <b-tag v-if="props.row.active == 'N'" class="mt-1 inactive">{{$t('common.Inactive')}}</b-tag>
                        </div>                        
                      </b-table-column>
                      <b-table-column field="sw_group_name" :label="$t('sw.posted_in')" :searchable="searchEnabled">
                        {{props.row.sw_group_name !== null ? props.row.sw_group_name : "-"}}
                      </b-table-column>
                      <b-table-column field="latest_comment.created" :label="$t('sw.latest_reply')">
                        <span v-if="props.row.latest_comment !== null">{{props.row.latest_comment.created | moment("DD-MM-YYYY")}} door {{props.row.latest_comment.posted_by_name}}</span>
                        <span v-else>-</span>                    
                      </b-table-column>
                      <!-- <b-table-column field="insights.engagement_score">
                        <span class="d-flex align-items-center">
                          <i class="icon top fas fa-tachometer-alt mr-1"/>{{ props.row.insights.engagement_score }}
                        </span>                        
                      </b-table-column>                  
                      <b-table-column field="insights.reach.percentage" custom-key="percentage">
                        <span class="d-flex align-items-center">
                          <i class="icon top fas fa-percentage mr-1"/>{{ props.row.insights.reach.percentage }}
                        </span>
                      </b-table-column> -->
                      <b-table-column field="total_views" custom-key="views">
                        <span class="d-flex align-items-center">
                          <i class="icon top far fa-user mr-1"/>{{ props.row.total_views }} <span v-if="props.row.unique_views > 0" class="ml-1">({{props.row.unique_views}})</span>
                        </span>                        
                      </b-table-column>                  
                      <b-table-column field="total_replies" custom-key="replies">
                        <span class="d-flex align-items-center pointer" @click="openSidebarRight('sw_post_preview', { post_id_external: props.row.post_id_external })">
                          <i class="icon top far fa-comments mr-1"/>{{ props.row.total_replies }}
                        </span>
                      </b-table-column>
                      <b-table-column field="actions">
                        <div class="d-flex justify-content-lg-end">
                          <b-switch class="mb-0" v-model="props.row.is_hidden" size="is-small" @input="updatePost(props.row.post_id_external,  props.row);">{{$t('common.Hide')}}</b-switch>
                          <CDropdown v-if="checkPermission('connect.sw.postinsights') || checkPermission('connect.sw.neweditpost')" 
                                     color="primary"
                                     toggler-text=""
                                     :caret="false"
                                     class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem v-if="checkPermission('connect.sw.postinsights')">
                              <div class="d-flex align-items-center" @click="showPostInsights( props.row.post_id_external )">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="far fa-chart-bar"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('common.Show_insights')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem v-if="checkPermission('connect.sw.neweditpost')">
                              <div class="d-flex align-items-center" @click="openSidebarRight('sw_post_details', { post_id_external:  props.row.post_id_external, schedule_for_datetime: null, back_to_preview: false });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-pen"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('sw.edit_post')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                          </CDropdown>
                        </div>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="p-2 text-center">                        
                        <span>{{$t('sw.no_posts_found')}}</span>
                      </div>                
                    </template>                
                  </b-table>                
                </CCol>
              </CRow>
            </div>
          </div>

          <div v-if="activeView === 'cards'" class="cards">
            <div class="overview_tools">
              <CRow>
                <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                  <multiselect
                    class="data_table open_absolute"
                    v-model="filter.sw_groups" 
                    :options="swGroups" 
                    :multiple="true"
                    :close-on-select="false"
                    :placeholder="$t('sw.filter_groups2')" 
                    :selectLabel="$t('common.Add_group')" 
                    :selectedLabel="$t('Added')"
                    :deselectLabel="$t('Remove')"
                    track-by="sw_group_name" 
                    label="sw_group_name"
                    @input="getPostData()">
                    <span slot="noResult">{{$t('sw.no_groups_found2')}}</span>
                  </multiselect>
                </CCol>
                <!-- <CCol cols="6" xl="6" class="pt-0 pb-0 text-right">
                  <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="showInactive" size="is-small" @input="getPostData()">{{$t('common.Show_hidden')}}</b-switch>
                </CCol> -->
              </CRow>
            </div>            
            <div class="card_container" id="masonryWall">
              <loadingSpinner mode="auto" v-if="loadingMasonryPosts" :content="$t('common.Loading')"/>

              <vue-masonry-wall v-else :items="posts" :options="{width: 500}" :ssr="{columns: 2}" class="pt-0 pb-0">
                <template #default="{item}">
                  <swPostCard :postData="item"
                              :modules="modules"
                              :platformPermissions="platformPermissions"
                              :navigatable="true"
                              :editable="true"
                              :previewable="true"
                              :preview="false"
                              :showReplies="false"
                              :showInsights="true"
                              :scrollableContent="false"
                              :openedInExp="true"/>
                </template>                
              </vue-masonry-wall>

              <CRow v-if="allMasonryPostsLoaded" class="m-0 pt-0 pb-0 all_posts_loaded">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="p-0">        
                  <CCard class="mt-0 mb-0">
                    <CCardBody>
                      <div class="d-flex align-items-center w-100 mb-2">
                        <div v-html="getTwemoji('😁')"></div>
                        <span class="ml-1">{{ $t('common.Reached_the_end') }}</span>
                      </div>
                      
                      <CButton class="m-0" color="primary" @click="scrollToTop()">
                        {{ $t('common.Back_to_top') }}
                      </CButton>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import VueMasonryWall from "vue-masonry-wall";

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import swPostCard from '@/components/connect/swPostCard.vue';

export default {  
  name: 'Posts',
  components: {
    Multiselect,
    loadingSpinner,
    noPermission,
    VueMasonryWall,
    swPostCard
  },
  data() {
    return {
      modules: {
        social_wall: 0,
        academy: 0,
        social_kb: 0,
        employee_loyalty_programme: 0
      },
      platformPermissions: [],
      platformPermissionsLoaded: false,
      posts: [],
      listPostsLoaded: false,
      loadingListPosts: false,
      masonryPostsLoaded: false,
      loadingMasonryPosts: false,
      weekDays: [],
      swGroups: [],
      filter: { sw_groups: [] },
      activeView: 'schedule',
      startDate: new Date(),
      previousDate: null,
      nextDate: null,
      searchEnabled: false,
      showInactive: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      totalItems: 0,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      videosUploading: [],
      listPostStartIndex: 0,
      allMasonryPostsLoaded: false
    }
  },
  methods: {
    onListPageChange(page) {
      // Set the listPostStartIndex value
      (page > 1) ? this.listPostStartIndex = (page - 1)*10 : this.listPostStartIndex = 0;
      // Get the posts for the list view   
      this.getListPosts();
    },
    getListPosts() {
      // Start the loader
      if(!this.listPostsLoaded) this.loadingListPosts = true;
      // Set the params
      let params = {};
      params.sw_groups = [];
      // Add the sw_group_id to the sw_groups array
      for(var g = 0; g < this.filter.sw_groups.length; g++) {
        params.sw_groups.push(this.filter.sw_groups[g].sw_group_id);
      }
      // Get the posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/posts/' + this.listPostStartIndex, params)
      .then(res => {
        // Clear the posts array
        this.posts = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_posts;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the posts to the posts array
        this.posts = res.data.data.posts;
        // Update the listPostsLoaded value
        this.listPostsLoaded = true;
        // Stop the loader
        this.loadingListPosts = false;
        // Hide inactive post when necessary
        // if(!this.showInactive) this.posts = this.posts.filter( i => ['Y'].includes( i.active ) );
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSchedulePosts() {
      // Set the params
      let params = {};      
      params.sw_groups = [];
      // Add the sw_group_id to the sw_groups array
      for(var g = 0; g < this.filter.sw_groups.length; g++) {
        params.sw_groups.push(this.filter.sw_groups[g].sw_group_id);
      }
      // Set the start_date param
      let year = this.startDate.getFullYear();
      let month = (this.startDate.getMonth() + 1).toString().padStart(2, '0');
      let date = this.startDate.getDate().toString().padStart(2, '0');
      params.start_date = year + '-' + month + '-' + date;
      // Get the scheduled posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/schedule', params)
      .then(res => {
        this.weekDays = res.data.data.week_days;
        // Set the previousDate and nextDate values
        this.previousDate = res.data.data.previous_date;
        this.nextDate = res.data.data.next_date;
        // Hide inactive weekday post when necessary
        // if(!this.showInactive) {
        //   for(var d = 0; d < this.weekDays.length; d++) {
        //     this.weekDays[d].posts = this.weekDays[d].posts.filter( i => ['Y'].includes( i.active ) );
        //   }
        // }
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getMasonryPosts() {
      // Start the loader
      if(!this.masonryPostsLoaded) this.loadingMasonryPosts = true;
      // Set the params
      let params = {};
      params.sw_groups = [];
      // Add the sw_group_id to the sw_groups array   
      for(var g = 0; g < this.filter.sw_groups.length; g++) {
        params.sw_groups.push(this.filter.sw_groups[g].sw_group_id);
      }      
      // Clear the posts array
      this.posts = [];
      // Get the current number of items
      let currentItemNumber = this.posts.length;      
      // Get the posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/posts/' + currentItemNumber, params)
      .then(res => {
        this.posts = res.data.data.posts;         
        // Hide inactive post when necessary
        // if(!this.showInactive) this.posts = this.posts.filter( i => ['Y'].includes( i.active ) );
        // Update the masonryPostsLoaded value
        this.masonryPostsLoaded = true;        
        // Stop the loader
        this.loadingMasonryPosts = false;       
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMoreMasonryPosts() {
      let params = {};
      params.sw_groups = [];
      // Add the sw_group_id to the sw_groups array
      for(var g = 0; g < this.filter.sw_groups.length; g++) {
        params.sw_groups.push(this.filter.sw_groups[g].sw_group_id);
      }
      // Get the current number of items
      let currentItemNumber = this.posts.length;
      // Set maximum of posts to 100
      if(currentItemNumber >= 100) {
        this.allMasonryPostsLoaded = true;
        return;
      }       
      // Get the posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/posts/' + currentItemNumber, params)
      .then(res => {
        let additionalPosts = res.data.data.posts;
        // Check if there are additional posts available
        if(additionalPosts.length > 0) {
          // If so, add them to the posts array
          for (let p = 0; p < additionalPosts.length; p++) {      
            this.posts.push(additionalPosts[p]);    
          }
        } else {
          // Update allMasonryPostsLoaded value
          this.allMasonryPostsLoaded = true;
        }     
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPostData(){
      if(this.activeView === 'schedule') {
        this.listPostStartIndex = 0;
        this.listPostsLoaded = false;
        this.allMasonryPostsLoaded = false;
        this.masonryPostsLoaded = false;

        this.getSchedulePosts()
      } else if(this.activeView === 'list') {
        this.allMasonryPostsLoaded = false;
        this.masonryPostsLoaded = false;

        this.getListPosts();
      } else if(this.activeView === 'cards') {
        this.listPostStartIndex = 0;
        this.listPostsLoaded = false;
        
        this.getMasonryPosts();

        setTimeout(function(){
          const masonryWall = document.querySelector('#masonryWall');
          masonryWall.addEventListener('scroll', e => {
            if(masonryWall.scrollTop + masonryWall.clientHeight >= masonryWall.scrollHeight) {
              this.getMoreMasonryPosts();
            }
          });
        }.bind(this), 100);
      }
    },
    getPostDetails(postIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + postIdExternal)
      .then(res => {        
        let updatedPost = res.data.data;
        let postIndex = null;
        // Check the activeView
        if(this.activeView === 'list' && this.$refs !== undefined && this.$refs.postTable !== undefined) {
          // Get the post index
          postIndex = this.$refs.postTable.data.findIndex(x => x.post_id == updatedPost.post_id);
          // Update the post in the table
          this.$refs.postTable.data[postIndex].title = updatedPost.title;
          this.$refs.postTable.data[postIndex].is_breaking_news = updatedPost.is_breaking_news;
          this.$refs.postTable.data[postIndex].is_hidden = updatedPost.is_hidden;
          this.$refs.postTable.data[postIndex].active = updatedPost.active;
          this.$refs.postTable.data[postIndex].total_replies = updatedPost.total_replies;
          // Hide (newly) inactive posts  
          // if(!this.showInactive) this.posts = this.posts.filter( i => ['Y'].includes( i.active ));

        } else if(this.activeView === 'schedule') {        
          // Loop through the weekDays
          for(var d = 0; d < this.weekDays.length; d++) {
            // Check if the weekday contains any posts
            if(this.weekDays[d].posts.length > 0) {
              // Get the index of the updated post
              postIndex = this.weekDays[d].posts.findIndex(x => x.post_id == updatedPost.post_id);
              // Update the post in the array
              if(postIndex >= 0) this.$set(this.weekDays[d].posts, postIndex, updatedPost);
            }
          }
                      
          // Hide (newly) inactive posts  
          if(!this.showInactive) {
            for(var d = 0; d < this.weekDays.length; d++) {
              this.weekDays[d].posts = this.weekDays[d].posts.filter( i => ['Y'].includes( i.active ) );
            }
          }
          
        } else if(this.activeView === 'cards') {
          // Get the post index
          postIndex = this.posts.findIndex(x => x.post_id_external == updatedPost.post_id_external);
          // Update the post in the array
          this.$set(this.posts, postIndex, updatedPost);
          // Filter the posts on active status
          // if(!this.showInactive) this.posts = this.posts.filter( i => ['Y'].includes( i.active ) );
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updatePost(id, data) {
      let postIdExternal = id;
      let params = data;
      
      (params.is_hidden == false) ? params.active = 'Y' : params.active = 'N';
      (params.is_breaking_news === false) ? params.breaking_news = 0 : params.breaking_news = 1;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + postIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('sw.Post_updated2'), type: 'is-success', duration: 2000 });
        // Get the post data or details according to the active view        
        // if(this.activeView === 'cards' && !this.showInactive) {
        //   this.getPostData();
        // } else {
        //   this.getPostDetails(postIdExternal);
        // }
        this.getPostDetails(postIdExternal);
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getSWGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flows/multiselect')
      .then(res => {
        this.swGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    openSidebarForDate(day) {
      let scheduledForDatetime = null;
      // Set the scheduledForDatetime value
      (day.is_current_date === 0) ? scheduledForDatetime = new Date(day.date + ' 10:00') : scheduledForDatetime = new Date();
      // Open the sidebar
      this.openSidebarRight('sw_post_details', { post_id_external:  null, scheduled_for_datetime: scheduledForDatetime, back_to_preview: false });  
    },
    showPostInsights (id) {
      this.$router.push({path: `/insights/connect/post/${id.toString()}`});     
    },
    scrollToTop() {
      const masonryWall = document.querySelector('#masonryWall');      
      // Scroll to the top of the element
      masonryWall.scrollTo({top: 0, behavior: 'smooth'})
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }, 
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }      
  },
  created: function() {
    this.checkModules();
    this.getPlatformPermissions();
  },
  mounted: function(){
    this.getPostData(); 
    this.getSWGroups();

    this.$bus.$on('sw_post_inserted', () => {
      this.getPostData();
    })

    this.$bus.$on('sw_post_updated', (postIdExternal) => {
      this.getPostDetails(postIdExternal);
    })

    this.$bus.$on('sw_reply_posted', (postIdExternal) => {      
      this.getPostDetails(postIdExternal);
    })

    this.$bus.$on('sw_reply_deleted', (postIdExternal) => {      
      this.getPostDetails(postIdExternal);
    })    

    this.$bus.$on('new_video_uploading', (videoExternalId) => {
      // Add the external video id to the videosUploading array
      this.videosUploading.push(videoExternalId);
    })
    
    this.$bus.$on('video_upload_finished', (videoExternalId) => {
      // Remove the external post id from the videosUploading array
      this.videosUploading = this.videosUploading.filter(function(uuid) {
        return uuid != videoExternalId;
      });
    })
  },
  beforeDestroy() {
    this.$bus.$off('sw_post_inserted');
    this.$bus.$off('sw_post_updated');
    this.$bus.$off('sw_reply_posted');
    this.$bus.$off('sw_reply_deleted');
    // this.$bus.$off('new_video_uploading');
    // this.$bus.$off('video_upload_finished');
  }
}
</script>

<style>
  #fileInput {
    display: none;
  }
</style>